<template>
  <div class="report cell-custom">
      <div class="glo-nav">数据走势</div>
      <van-cell class="report-filter" title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
      <div class="nav-char">
        <div v-for="(item,index) in navlist" :key="index" class="wid_3">
            <div :class="{'block': true,'active': active == index ? true : false}" @click="selectChart(index)">{{ item }}</div>
        </div>
      </div>
      <div :class="'glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div>
          <div v-if="loading">
              <van-loading type="spinner" vertical>加载中</van-loading>
          </div>
          <div v-else>
              <div v-if="active == 0"><canvas id="xaxisangleChart"></canvas></div>
              <div v-else-if="active == 1"><canvas id="vibampChart"></canvas></div>
              <div v-else-if="active == 2"><canvas id="averageChart"></canvas></div>
              <div v-else-if="active == 3"><canvas id="airTemperatureChart"></canvas></div>
              <div v-else-if="active == 4"><canvas id="airHumidnessChart"></canvas></div>
              <!-- <div v-else-if="active == 5"><canvas id="chartWater"></canvas></div> -->
          </div>
        </div>
        <tabbar />

        <!-- 筛选时间段 -->
        <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#07c160"/>
      </div>
  </div>
</template>

<script>
const F2 = require('@antv/f2');
import tabbar from '@/components/slope/NVtabbar.vue'
import moment from "moment";

export default {
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        active:0,
        filter:{
          time:{
            start:'',
            end:'',
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(),
            value:'',
            show:false
          }
        },
        vibampArr:[],
        xaxisangleArr:[],
        accAverageArr:[],
        airTemperatureArr:[],
        airHumidnessArr:[],
        // chartDataWater:[],
        projectCode:'',
        loading:true,
        navlist:['倾角','挠度','加速度','温度','湿度']
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;
      }
  },
  methods:{
    initDate(){
      const time = moment().valueOf();
      this.filter.time.end = moment().format('YYYY-MM-DD HH:mm:ss');
      this.filter.time.start = moment(time - 24*60*60*1000).format('YYYY-MM-DD HH:mm:ss') // 24小时之前
      this.filter.time.value = `24小时`;
      // this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
    },
    // 点击切换绘制
    selectChart(index){
      this.active = index;
      this.resetQuery();
    },
    resetQuery(){
      this.loading = true;
      if(this.active == 0){
        this.getSensorInfo().then( () => {
          this.xaxisangleShow();
        })
      }else if(this.active == 1){
        this.getSensorInfo().then( () => {
          this.vibampShow();
        })
      }else if(this.active == 2){
        this.getSensorInfo().then( () => {
          this.averageShow();
        })
      }else if(this.active == 3){
        //温度
        this.getMelvesInfo().then( () => {
          this.tempShow();
        })
      }else if(this.active == 4){
        // 湿度
        this.getMelvesInfo().then( () => {
          this.humiShow();
        })
      }
    },
    // 数据格式化
    getSensorInfo(){
      return new Promise( (resolve,reject) => {
        // 传感器
        this.$api.NEWBRIDGE.sensorTrend({
            projectCode:this.projectCode,
            beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
            endTime:this.filter.time.end ? `${this.filter.time.end} 00:00:00` : void 0,
        }).then( d =>{
            var xaxisangleArr = []; // 地表倾斜
            var vibampArr = []; // 地表位移
            var accAverageArr = [];
            for(let i=0;i<d.length;i++){
                d[i].date.forEach((e,index)=>{
                    vibampArr.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      vibamp:parseFloat(d[i].vibamp[index]),
                      name:d[i].name
                    });
                    accAverageArr.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      accAverage:parseFloat(d[i].accMaximum[index]),
                      name:d[i].name
                    });
                })
                d[i].xaxisangleDate.forEach((e,index)=>{
                    xaxisangleArr.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      xaxisangle:parseFloat(d[i].xaxisangle[index]),
                      name:d[i].name
                    });
                })
            }
            this.xaxisangleArr = xaxisangleArr;
            this.vibampArr = vibampArr;
            this.accAverageArr = accAverageArr;
            this.loading = false;
            // this.xaxisangleShow();
            resolve();
          })
        })
    },
    // 获取气象精灵走势图
    getMelvesInfo(){
        return new Promise( (resolve,reject) => {
          // 新版
          this.$api.MELVES.statisticsInfoM2({
            projectCode:this.projectCode,
            beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
            endTime:this.filter.time.end ? `${this.filter.time.end} 00:00:00` : void 0,
          }).then( data =>{
              var airTemperatureArr = []; // 空气温度
              var airHumidnessArr = []; // 空气湿度
              for(let i=0;i<data.length;i++){
                  data[i].time.forEach((e,index)=>{
                      airTemperatureArr.push({
                          time:e.substring(0,e.lastIndexOf(':')),
                          airTemperature:parseFloat(data[i].airTemperature[index]),
                          name:data[i].devName
                      });
                      airHumidnessArr.push({
                        time:e.substring(0,e.lastIndexOf(':')),
                        airHumidness:parseFloat(data[i].airHumidness[index]),
                        name:data[i].devName
                      });
                  })
              }
              this.airTemperatureArr = airTemperatureArr;
              this.airHumidnessArr = airHumidnessArr;
              this.loading = false;
              resolve();
          })
        })
    },
    // 绘制表面倾斜图表
    xaxisangleShow(){
      let that = this;
      this.$nextTick(()=>{
        const chart = new F2.Chart({
          id: 'xaxisangleChart',
          pixelRatio: window.devicePixelRatio
        });
        chart.source(this.xaxisangleArr);
        chart.scale('date', {
          type: 'timeCat',
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range:[0,1]
        });
        chart.scale('xaxisangle', {
          tickCount: 6
        });
        chart.axis('date', {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = 'left';
            } else if (index === total - 1) {
              textCfg.textAlign = 'right';
            }
            return textCfg;
          }
        });
        chart.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: function onChange(obj) {
            that.active != 0 ? chart.tooltip(false) : void 0 ;
            const legend = chart.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach(function(item) {
              map[item.name] = item;
            });
            tooltipItems.forEach(function(item) {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value;
              }
            });
            legend.setItems(Object.values(map));
          }
        });
        chart.line({
          connectNulls: true // 将空数据连接
        }).shape('smooth').position('date*xaxisangle').color('name');
        chart.render();
      })
    },
    // 绘制地表位移图表
    vibampShow(){
      let that = this;
      this.$nextTick( () => {
          const chart = new F2.Chart({
            id: 'vibampChart',
            pixelRatio: window.devicePixelRatio
          });
          chart.source(this.vibampArr);
          chart.scale('date', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart.scale('vibamp', {
            tickCount: 5
          });
          chart.axis('date', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              that.active != 1 ? chart.tooltip(false) : void 0 ;
              const legend = chart.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            }
          });
          chart.line().position('date*vibamp').color('name');
          chart.render();
      })
    },
    // 绘制平均速度曲线
    averageShow(){
      let that = this;
      this.$nextTick(()=>{
        const chart = new F2.Chart({
          id: 'averageChart',
          pixelRatio: window.devicePixelRatio
        });
        chart.source(this.accAverageArr);
        chart.scale('date', {
          type: 'timeCat',
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range:[0,1]
        });
        chart.scale('accAverage', {
          tickCount: 6
        });
        chart.axis('date', {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = 'left';
            } else if (index === total - 1) {
              textCfg.textAlign = 'right';
            }
            return textCfg;
          }
        });
        chart.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: function onChange(obj) {
            that.active != 2 ? chart.tooltip(false) : void 0 ;
            const legend = chart.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach(function(item) {
              map[item.name] = item;
            });
            tooltipItems.forEach(function(item) {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value;
              }
            });
            legend.setItems(Object.values(map));
          }
        });
        chart.line({
          connectNulls: true // 将空数据连接
        }).shape('smooth').position('date*accAverage').color('name');
        chart.render();
      })
    },
    // 筛选时间确认事件
    timeConfirm(date) {
      const [start, end] = date;
      this.filter.time.show = false;
      this.filter.time.start = moment(start).format("YYYY-MM-DD");
      this.filter.time.end = moment(end).format("YYYY-MM-DD");
      this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;

      this.resetQuery();
    },

    tempShow(){
       this.$nextTick(()=>{
          // 生成空气温度图表
          const chart = new F2.Chart({
            id: 'airTemperatureChart',
            pixelRatio: window.devicePixelRatio
          });
          chart.source(this.airTemperatureArr);
          chart.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart.scale('airTemperature', {
            tickCount: 6
          });
          chart.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          
          chart.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart.get('legendController').legends.top[0];
              legend.setItems(chart.getLegendItems().country);
            }
          });
          chart.line({
            connectNulls: true // 将空数据连接
          }).shape('smooth').position('time*airTemperature').color('name');
          chart.render();
       })
    },
    humiShow(){
        this.$nextTick(()=>{
          // 生成湿度图
          const chart = new F2.Chart({
            id: 'airHumidnessChart',
            pixelRatio: window.devicePixelRatio
          });
          chart.source(this.airHumidnessArr);
          chart.scale('time', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart.scale('airHumidness', {
            tickCount: 5
          });
          chart.axis('time', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
            xTip: (obj)=>{
              return obj
            },
            onChange: function onChange(obj) {
              const legend = chart.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            },
            onHide: function onHide() {
              const legend = chart.get('legendController').legends.top[0];
              legend.setItems(chart.getLegendItems().country);
            }
          });
          chart.line().position('time*airHumidness').color('name');
          chart.render();
        })
    },
    wlmsShow(){
      this.$nextTick(() => {
        // 水位变化
        this.chart0 = new F2.Chart({
          id: "chartWater",
          pixelRatio: window.devicePixelRatio,
        });
        this.chart0.source(this.chartDataWater);
        this.chart0.scale("date", {
          type: "timeCat",
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range: [0, 1]
        });

        const waterOpt = {
          tickCount: 6,
          min:0,
          tickInterval:10  // 数值范围10
        }
        const otherOpt = {
          tickCount: 6,
          min:0,
        }
        this.chart0.scale("value", this.currentScene === 'water' ? waterOpt : otherOpt);
        this.chart0.axis("date", {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = "left";
            } else if (index === total - 1) {
              textCfg.textAlign = "right";
            }
            return textCfg;
          },
        });
        this.chart0.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: (obj) => {
            const legend = this.chart0.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach((item)=>{
              map[item.name] = item;
            });
            tooltipItems.forEach((item)=> {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                this.currentScene === 'water' ? map[name].value = value + 'cm' :map[name].value = value + 'm';
              }
            });
            legend.setItems(Object.values(map));
          },
          onHide:() => {
            const legend = this.chart0.get('legendController').legends.top[0];
            legend.setItems(this.chart0.getLegendItems().country);
          }
        });
        this.chart0
          .line({
            connectNulls: true, // 将空数据连接
          })
          .shape("smooth")
          .position("date*value").color('name')
        this.chart0.render();
      });
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      if(this.projectCode){
        this.getSensorInfo().then( () => {
          this.xaxisangleShow();
        })
      }
      this.initDate();
  }
}
</script>

<style lang="scss" scoped>
.glo-content{
  padding: 0 4% 4% 4%;
}
.report-filter{
  text-align: left;
}
.nav-char{
  padding: 4%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .wid_3{width: 30%;margin-bottom: 20px;}
  .block{
    line-height: 3;
    padding: 2% 4%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
    margin-right: 2%;
  }
  .active{
    background:linear-gradient(#40b5d8 0%, #6ed4c6 85%);
    color: whitesmoke;
  }
}
#xaxisangleChart,
#vibampChart,
#averageChart,
#airHumidnessChart,
#airTemperatureChart,
#chartWater{width: 100%;height: 26rem;}
.title{
  text-align: left;font-size: .95rem;line-height:2;padding-left: 4%;
  .fa{color: #1989fa;font-size: 1.2rem;padding-right: 2%;}
}
</style>